import React, { useEffect, useState } from "react";
import styles from './Portfolio.module.css';
import PortfolioTiles from "./PortfolioTiles";
import { PortfolioProjectArray } from "../../PageData";
import ProjectTypeSelect from "./ProjectTypeSelect";
import BannerSwitcher from "./BannerSwitcher";

export default function Portfolio(){

    useEffect(() => {
        window.scrollTo(0,0);
    },[]);

    const [projects,setProjects] = useState(PortfolioProjectArray);

    const tilingFunction = () => {
        const noProjects = PortfolioProjectArray.length;
        const sizeArray = [5,4,5,4,5,4,4,4,4];
        const gridColumnArray = ['1/2','2/3','3/4'];
        let tiles = [];
        for(let i = 0; i < noProjects; i++){
            let remainder;
            if(i !== 0){
                remainder = i % 9;
               
            } else {
                remainder = 0;
            }
            let col = i % 3;
            tiles.push(
                <div className={styles.tile} style={{gridRow: `span ${sizeArray[remainder]}`, gridColumn: gridColumnArray[col]}}>
                    <PortfolioTiles data={PortfolioProjectArray[i]}/>
                </div>
            );
        }
        return(
            tiles.map((data) => data)
        )
    }

    const bannerArray = ['./imgs/redditch/redditch_final.jpg','./imgs/vulco_premio_rollout/darka_concrete.jpg','./imgs/hiq_bicester/logo.jpg'];

    return(
        <>
        <BannerSwitcher imgPathArray={bannerArray}/>
        <main>
            <div className={styles.maskContainer}>
                
            </div>
            <div className={styles.titleContainer}>
                <h1>Projects</h1>
            </div>
            <div className={styles.bodySection}>
                {/* <div className={styles.projectTypeContainer}>
                    <ProjectTypeSelect />
                </div> */}
                <div className={styles.portfolioGrid}>
                    {tilingFunction()}
                </div>
                <div className={styles.portfolioGridMobile}>
                    {PortfolioProjectArray.map((data) =>  <PortfolioTiles data={{...data, uniqueId: data.uniqueId+'mobile'}}/>)}
                </div>
            </div>
        </main>
        </>
    )
}