import React, { useEffect, useState } from "react";
import styles from './Header.module.css';
import { ReactComponent as Logo} from '../../imgs/Stratela_logo_white-1.svg';
import { NavLink, useNavigate } from "react-router-dom";

export default function Header({navigateToElement}){

    const navigate = useNavigate();

    const [mobileVersion,setMobileVersion] = useState(true);
    const [navBarShow,setNavBarShow] = useState(false);
    const [headerColor,setHeaderColor] = useState(false);

    const toggleNavBar = () => {
        setNavBarShow((prev) => !prev);
    }
    
    const onResizeFunction = () => {
        const newViewportWidth = window.innerWidth;
        setMobileVersion(newViewportWidth < 900);
        setNavBarShow(newViewportWidth > 900);
        console.log(window.scrollY);
    }

    const onScrollFunction = () => {
        setHeaderColor(window.scrollY > 0);
    }

    useEffect(() => {
        onResizeFunction();
        window.addEventListener('resize', onResizeFunction);
        window.addEventListener('scroll', onScrollFunction);
        return () => {
            window.removeEventListener('resize', onResizeFunction);
            window.removeEventListener('scroll', onScrollFunction);
        }
    },[]);
    
    const handleLogoClick = () => {
        navigate('/home');
    }

    const handleNavClick = () => {
        if(mobileVersion){
            setNavBarShow(false);
        }
    }

    return (
        <header className={`${headerColor? styles.headerColor : ''}`}>
           <div className={styles.imgContainer} onClick={handleLogoClick}>
                <Logo />
           </div>
           <div className={`${styles.burgerMenuButton} ${mobileVersion? styles.displayBlock : styles.hide}`} onClick={toggleNavBar}>
                <i className={`fi fi-rr-menu-burger`}></i>
           </div>
           <nav className={`${navBarShow? styles.displayBlock : styles.hide}`}>
                <ul>
                    <li>
                        <NavLink to="home" onClick={handleNavClick}>Home</NavLink>
                    </li>
                    <li>
                        <NavLink to="aboutUs" onClick={() => navigateToElement('/home','aboutUsSection')}>About Us</NavLink>
                    </li>
                    <li>
                        <NavLink to="portfolio" onClick={handleNavClick}>Projects</NavLink>
                    </li>
                    <li>
                        <NavLink to="contact" onClick={handleNavClick}>Contact Us</NavLink>
                    </li>
                </ul>
           </nav>
        </header>
    )
}