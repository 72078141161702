import React, { useState , useEffect } from "react";
import styles from './Root.module.css';
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { Outlet, useNavigate, useParams } from "react-router-dom";

function Root(){

    const navigate = useNavigate();

    const currPage = useParams();

    const [mobileVersion,setMobileVersion] = useState(true);

    const onResizeFunction = () => {
        const newViewportWidth = window.innerWidth;
        setMobileVersion(newViewportWidth < 900);
    }

    useEffect(() => {
        onResizeFunction();
        window.addEventListener('resize', onResizeFunction);
        return () => {
            window.removeEventListener('resize', onResizeFunction);
        }
    },[]);

    const navigateToElement = (page,elementId) =>  {
        if(page === window.location.pathname){
            const element = document.getElementById(elementId);
            element.scrollIntoView({behavior: 'smooth'});
        } else {
            navigate(page);
            const observer = new MutationObserver(function (mutations, observer){
                const element = document.getElementById(elementId);
                if(element){
                    element.scrollIntoView({behavior: 'smooth'});
                    observer.disconnect();
                }
            });
            observer.observe(document.body,{childList: true, subtree: true});
        }
    }

    return (
        <div className={styles.rootContainer}>
            <Header navigateToElement={navigateToElement}/>
            <Outlet context={{mobileVersion}} />
            <Footer navigateToElement={navigateToElement}/>
        </div>
    );
}

export default Root;