import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import styles from './HoverTextPara.module.css';

export default function HoverTextPara({mobileVersion,data}) {
    const {headingText,bodyText} = data;

    const [active,setActive] = useState(false);
    
    const activeTrue = () => {
        setActive(true);
    }

    const activefalse = () => {
        setActive(false);
    }

    const toggleActive = () => {
        setActive((prev) => !prev);
    }

    return (
        <div onMouseEnter={activeTrue} onClick={toggleActive} onMouseLeave={activefalse} className={`${styles.container} ${active? styles.active : ''}`}>
            <div className={styles.headingContainer}>
                <h6 className={`${styles.icon} ${active? styles.activeHeading : styles.inActiveHeading}`}>{active? '-' : '+'}</h6>
                <h6 className={`${styles.heading} ${active? styles.activeHeading : styles.inActiveHeading}`}>{headingText}</h6>
            </div>
            <p className={`${active? styles.showText : styles.hideText}`}>{bodyText}</p>
        </div>
    )

}