import './App.css';
import { Route , Routes , BrowserRouter, Navigate } from 'react-router-dom';
import Root from './components/root/Root';
import Home from './components/home/Home';
import Portfolio from './components/portfolio/Portfolio';
import PortfolioPage from './components/portfolio/PortfolioPage';
import Contact from './components/contactUS/Contact';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Root/>}>
            <Route index element={<Navigate to="home"/>}/>
            <Route path="home" element={ <Home/> }/>
            <Route path="aboutUs" element={ <Home/> }/>
            <Route path="portfolio" element={ <Portfolio/> }/>
            <Route path="portfolio/:id" element={ <PortfolioPage/> }/>
            <Route path="contact" element={ <Contact/> }/>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
