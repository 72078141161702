import React , { useEffect, useState } from "react";
import styles from './Carousel.module.css';

export default function Carousel({id,imgArray}){

    const [carouselPos,setCarouselPos] = useState(0);
    const [selected,setSelected] = useState(0);

    useEffect(() => {
        const viewportWidth = document.body.clientWidth;
        console.log(selected);
        setCarouselPos((0.3*viewportWidth)-(selected*0.4*viewportWidth));
    },[selected]);

    let i = 0;
    let j = 0;

    return(
        <div className={styles.largeContainer}>
            <div id={id} className={styles.container}>
                <div id={`${id}_inner`} className={styles.containerInner} style={{left: `${carouselPos}px`}}>
                    {imgArray.map((img) => {
                        let k = i;
                        i++;
                        return (
                            <div onClick={() => setSelected(k)} className={`${styles.imageContainer} ${selected === k? styles.selected : ''}`}>
                                <div className={styles.images} style={{backgroundImage: `url(${img.img})`}}></div>
                            </div>
                        )
                    })}
                </div>
                {/* <div id={`${id}_left`} onClick={incrementLeft} className={styles.arrowLeft}>&#60;</div>
                <div id={`${id}_right`} onClick={incrementRight} className={styles.arrowRight}>&#62;</div> */}
            </div>
            <div className={styles.imgDescription}>
                <h6><strong>{imgArray[selected].name}</strong></h6>
                <p>{imgArray[selected].description}</p>
            </div>
            <div className={styles.circleSelectorContainer}>
                {imgArray.map((img) => {
                        let k = j;
                        j++;
                        return (
                            <div className={`${styles.circleSelector} ${selected === k? styles.selected : ''}`} onClick={() => setSelected(k)}>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}