import React from "react";
import styles from './Contact.module.css';
import { ReactComponent as Locator} from '../../imgs/contact/locator.svg';
import emailjs from '@emailjs/browser';

export default function Contact(){

    const sendEmail = (e) => {
        e.persist();
        e.preventDefault();
        console.log(e.target.name.value)
        console.log(e.target.email.value)
        console.log(e.target.mobile.value)
        console.log(e.target.message.value)
        emailjs
        .sendForm(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_TEMPLATE_ID,
            e.target,
            process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
            (result) => {
                console.log('message sent');
            },
            (error) => {
                console.log('error');
            }
        );
        e.target.reset();
    }

    return (
        <main>
            <section id="aboutUsSection" className={styles.aboutUsSection}>
                <div className={`${styles.circleMask}`}>
                    <div className={styles.sectionUpper}>
                        <div className={styles.sectionText}>
                            <h2>Let's see if<br/> we can help</h2>
                        </div>
                    </div>
                </div>
                <div >
                    <div className={styles.sectionLower}>
                        <div className={styles.lowerTextContainer}>
                            <div className={styles.sectionText2}>
                                <h4>We listen first,<br/>then talk second</h4>
                                <div className={styles.addressDiv}>
                                    <div>
                                        <p><strong>Studio:</strong></p>
                                        <h6>Tadworth</h6>
                                        <p className={styles.grey}>Sandy Crest<br/>Millfield Ln,<br/>Tadworth<br/>KT20 6RT<br/>United Kingdom</p>
                                    </div>
                                    <div>
                                        <p><strong>Registered Office:</strong></p>
                                        <h6>London</h6>
                                        <p className={styles.grey}>124 City Road,<br/>London,<br/>EC1V 2NX<br/>United Kingdom</p>
                                    </div>
                                </div>
                                <p className={styles.grey}><strong>Tel:</strong> 07813 086642</p>
                                <p className={styles.grey}><strong>Email:</strong> info@stratela.co.uk</p>
                            </div>
                            <div className={styles.contactForm}>
                                <h4>Get in touch</h4>
                                <form onSubmit={sendEmail}>
                                    <label>Your name *<input name="name"/></label>
                                    <label>Your email address *<input name="email"/></label>
                                    <label>Mobile number<input name="mobile"/></label>
                                    <label>How can we help?<textarea name="message"></textarea></label>
                                    <input type="submit" value="SEND MESSAGE"/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={styles.map}>
                <Locator className={styles.locator}/>
            </section>
        </main>
    )
}