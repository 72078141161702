import React from "react";
import styles from './Footer.module.css';
import {ReactComponent as InstaLogo} from '../../imgs/Instagram_Glyph_White.svg';
// import { useNavigate } from "react-router-dom";

export default function Footer({navigateToElement}){

    const navigateAboutUs = () => {
        navigateToElement('/home','aboutUsSection');
    }

    return (
        <footer>
            <div>
                <h6>Company</h6>
                <ul>
                    <li>
                        <button className={styles.footerLinks} onClick={() => navigateToElement('/home','aboutUsSection')}>About Us</button>
                    </li>
                    <li>
                        <button className={styles.footerLinks} onClick={() => navigateToElement('/home','valuesSection')}>Values & Principles</button>
                    </li>
                    <li>
                        <button className={styles.footerLinks} onClick={() => navigateToElement('/home','visionSection')}>Vision & Purpose</button>
                    </li>
                    <li>
                        <button className={styles.footerLinks} onClick={() => navigateToElement('/home','ourServicesSection')}>Our Services</button>                        
                    </li>
                </ul>

            </div>
            <div>
                <h6>Get in touch</h6>
                <p>Sandy Crest, Millfield Lane, <br/>Tadworth KT20 6RT</p>
                <div>
                    <i class="fi fi-br-envelope"></i>
                    <p>info@stratela.co.uk</p>
                </div>
            </div> 
            <div>
                <h6>Follow us</h6>
                <div className={styles.logoContainer}>
                    <InstaLogo className={styles.instaLogo}/>
                    <img src={require('../../imgs/Facebook_Logo_Secondary.png')} />
                    <img src={require('../../imgs/linkedin-app-white-icon.webp')} />
                </div>
            </div>  
        </footer>
    )
}